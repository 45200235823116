import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({

    tabContent: {
        padding: theme.spacing(2)
    }

}))


export default useStyles;