import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345
    },

    cover: {
        height: 150

    },
    paper: {
        padding: theme.spacing(2)
    }

}))


export default useStyles;