import React, { useEffect, useState } from 'react';


import useStyles from './styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { currency } from '../../../utils/formatter';
import format from 'date-fns/format';
import AppPageLoading from '../../../components/AppPageLoading';
import { useFirebase } from '../../../components/FirebaseProvider';
import { useCollection } from 'react-firebase-hooks/firestore';

function Affiliate({ affiliate }) {
    const classes = useStyles();
    const user = affiliate.data();
    const { firestore } = useFirebase()
    const komisiCol = firestore.collection(`komisi`)
    const [querySnapshot, loading] = useCollection(komisiCol.where('userId', '==', affiliate.id).where('status', '==', 'active'));
    const [komisiItems, setKomisiItems] = useState([]);
    const [balance, setBalance] = useState(0);
    useEffect(() => {

        if (querySnapshot) {

            if (!querySnapshot.empty) {
                const activeItems = querySnapshot.docs.map(doc => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                }).sort((a, b) => {

                    return b.createdAt.toMillis() - a.createdAt.toMillis();
                })
                setKomisiItems(activeItems);

                const totalBalance = activeItems.reduce((sum, val) => {
                    return sum + val.komisi
                }, 0);

                setBalance(totalBalance);
            }

        }
    }, [querySnapshot])
    if (loading) {
        return <AppPageLoading />
    }


    return <>
        <Typography component="h1" variant="h5" paragraph>Affiliate Program</Typography>


        <Grid container spacing={5}>
            <Grid item xs={12} container direction="column">
                <TextField
                    className={classes.referralInput}
                    variant="outlined"
                    label="Referral URL React Firebase"
                    defaultValue={`https://jagoreact.com/?ref=${user.refid}`}
                    margin="normal"
                    InputProps={{
                        readOnly: true
                    }}
                />
                <TextField
                    className={classes.referralInput}
                    margin="normal"
                    variant="outlined"
                    label="Referral URL React Serverless"
                    defaultValue={`https://serverless.jagoreact.com/?ref=${user.refid}`}
                    InputProps={{
                        readOnly: true
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" paragraph>Dapatkan komisi {user.komisi * 100}% untuk setiap pembelian produk JagoReact.com melalui link referral anda.</Typography>
                <Typography>informasi: info@jagoreact.com/089653023212</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" paragraph>Total Komisi Aktif: {currency(balance)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" paragraph>Daftar Komisi Aktif</Typography>

                <Table className={classes.table} >
                    <TableHead>
                        <TableRow>

                            <TableCell >Order ID</TableCell>
                            <TableCell >Komisi</TableCell>
                            <TableCell >Tanggal</TableCell>
                            <TableCell >Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {komisiItems.length <= 0 &&
                            <TableRow >
                                <TableCell colSpan={3} >Belum ada komisi</TableCell>
                            </TableRow>
                        }
                        {komisiItems.map((komisi) => {
                            const komisiData = komisi;

                            return <TableRow key={komisi.id} >

                                <TableCell >{komisiData.orderId}</TableCell>
                                <TableCell >{currency(komisiData.komisi)}</TableCell>
                                <TableCell >{format(komisiData.createdAt.toDate(), 'DD-MM-YYYY HH:mm')}</TableCell>
                                <TableCell >{komisiData.status}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    </>
}

export default Affiliate;