import React, { useEffect, useState } from 'react';


import useStyles from './styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PayIcon from '@material-ui/icons/Send';
import { currency } from '../../../utils/formatter';
import format from 'date-fns/format';
import AppPageLoading from '../../../components/AppPageLoading';
import { useFirebase } from '../../../components/FirebaseProvider';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';

function Komisi() {
    const classes = useStyles();

    const { firestore } = useFirebase()
    const { enqueueSnackbar } = useSnackbar();
    const komisiCol = firestore.collection(`komisi`)
    const [querySnapshot, loading] = useCollection(komisiCol.where('status', '==', 'active'));
    const [komisiItems, setKomisiItems] = useState([]);
    const [balance, setBalance] = useState(0);
    const [filter, setFilter] = useState('');
    useEffect(() => {

        if (querySnapshot) {

            if (!querySnapshot.empty) {
                let items = querySnapshot.docs.map(doc => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                }).sort((a, b) => {

                    return b.createdAt.toMillis() - a.createdAt.toMillis();
                })

                if (filter) {
                    items = items.filter(item => {
                        return item.email.toLowerCase().includes(filter.toLowerCase())
                    })
                }
                setKomisiItems(items);

                const totalBalance = items.reduce((sum, val) => {
                    return sum + val.komisi
                }, 0);

                setBalance(totalBalance);
            }
        }
    }, [querySnapshot, filter])


    const bayarKomisi = (komisiSnap) => async e => {
        await firestore.doc(`komisi/${komisiSnap.id}`).set({ status: 'Dibayar' }, { merge: true })
        enqueueSnackbar('Komisi dibayar', { variant: 'success' })
    }
    if (loading) {
        return <AppPageLoading />
    }



    return <>

        <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
                <TextField
                    label="Email"
                    value={filter}
                    onChange={(e) => {
                        setFilter(e.target.value)
                    }}

                />
            </Grid>
            <Grid item xs={6} md={6}>
                <Typography>Total Komisi: {currency(balance)}</Typography>
            </Grid>
            <Grid item xs={12}>


                <Table className={classes.table} >
                    <TableHead>
                        <TableRow>
                            <TableCell>Ref</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell >Order ID</TableCell>
                            <TableCell >Komisi</TableCell>
                            <TableCell >Tanggal</TableCell>
                            <TableCell >Status</TableCell>
                            <TableCell >Aksi</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {komisiItems.length <= 0 &&
                            <TableRow >
                                <TableCell colSpan={3} >Belum ada komisi</TableCell>
                            </TableRow>
                        }
                        {komisiItems.map((komisi) => {
                            const komisiData = komisi

                            return <TableRow key={komisi.id} >
                                <TableCell>{komisiData.displayName}</TableCell>
                                <TableCell>{komisiData.email}</TableCell>
                                <TableCell >{komisiData.orderId}</TableCell>
                                <TableCell >{currency(komisiData.komisi)}</TableCell>
                                <TableCell >{format(komisiData.createdAt.toDate(), 'DD-MM-YYYY HH:mm')}</TableCell>
                                <TableCell >{komisiData.status}</TableCell>
                                <TableCell><IconButton onClick={bayarKomisi(komisi)}><PayIcon /></IconButton></TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    </>
}

export default Komisi;