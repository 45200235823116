import React, { useState } from 'react';

// import Material UI components
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import notistack hook untuk menampilkan komponen Snackbar Material UI
import { useSnackbar } from 'notistack';
// import styles
import useStyles from './styles';

// import komponen React Router DOM untuk navigasi ke halaman LupaPassword
import { Link, Redirect } from 'react-router-dom';

// import valdiator 
import isEmail from 'validator/lib/isEmail';

// import komponen umum / app
import AppLoading from '../../components/AppLoading';

// import firebase hook
import { useFirebase } from '../../components/FirebaseProvider';

// buat sebuah fungsi komponen dengan nama LupaPassword
function LupaPassword(props) {
    const classes = useStyles();

    const [form, setForm] = useState({
        email: ''
    });

    const [error, setError] = useState({
        email: ''
    });


    const [isSubmitting, setSubmitting] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    // useAuthState hook untuk memonitor perubahan pada user state
    const { loading, user, auth } = useFirebase();

    // fungsi untuk menangani event onChange pada TextField material-ui
    const handleChange = e => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

        setError({
            ...error,
            [e.target.name]: ''
        });
    };

    // fungsi untuk memvalidasi inputan pengguna email,password, dan repeat_password
    const validate = () => {

        const newError = { ...error };

        if (!form.email) {
            newError.email = 'Email wajib diisi';
        } else if (!isEmail(form.email)) {
            newError.email = 'Email tidak valid';
        }


        return newError;
    };

    // fungsi (async) untuk menangani registrasi
    const handleSubmit = async e => {
        e.preventDefault();

        const findErrors = validate();

        if (Object.keys(findErrors).length > 0
            && Object.values(findErrors).some(err => err !== '')) {
            setError(findErrors);
        } else {
            try {
                setSubmitting(true);
                const actionCodeSettings = {
                    url: `${window.location.origin}/login`
                };
                await auth.sendPasswordResetEmail(form.email, actionCodeSettings);
                enqueueSnackbar(`Cek kotak masuk email: ${form.email}, sebuah tautan untuk me-reset password telah dikirim`)
                setSubmitting(false);
            } catch (e) {
                const newError = {};
                switch (e.code) {
                    case 'auth/user-not-found':
                        newError.email = 'Email tidak terdaftar';
                        break;
                    case 'auth/invalid-email':
                        newError.email = 'Email tidak valid';
                        break;
                    default:
                        newError.email = 'Terjadi kesalahan silahkan coba lagi: ' + e.code;
                        break;
                }
                setError(newError);
                setSubmitting(false);
            }
        }
    };

    if (loading) {
        return <AppLoading />
    }

    if (user) {
        return <Redirect to="/" />
    }


    return (
        <Container maxWidth="xs" component="main">
            <Paper className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Lupa Password
                </Typography>
                <Typography component="h2" variant="h6">
                   JagoReact.com
                </Typography>
                <form onSubmit={handleSubmit} className={classes.form} noValidate>
                    <TextField
                        id="email"
                        name="email"
                        type="email"
                        fullWidth
                        value={form.email}
                        label="Alamat Email"
                        placeholder="you@email.com"
                        className={classes.textField}
                        onChange={handleChange}
                        helperText={error.email}
                        error={error.email ? true : false}
                        disabled={isSubmitting}
                        required
                        variant="outlined"

                    />


                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Kirim
                        </Button>
                        <Button
                            variant="contained"
                            component={Link}
                            to={'/login'}
                            disabled={isSubmitting}
                            size="large"
                        >
                            Login
                        </Button>
                    </div>
                </form>
            </Paper>
        </Container>);
}


export default LupaPassword;