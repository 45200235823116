import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';


export default function usePersistRef() {
    const location = useLocation();
    useEffect(() => {
        if (location.search) {
            const query = qs.parse(location.search);
            const refid = query.ref
            if (refid) {
                if (!localStorage.getItem('refid') && refid && refid !== 'undefined') {
                    localStorage.setItem('refid', refid);
                }
            }

        }
    }, [location.search])

}
