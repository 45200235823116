import React, { useState, useEffect } from 'react';

// material-ui 
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useFirebase } from '../../../components/FirebaseProvider';

import { useCollection } from 'react-firebase-hooks/firestore';
import { currency } from '../../../utils/formatter';
import { renderProduk } from '../../../utils/helper';
import format from 'date-fns/format';
import Image from 'material-ui-image';
import AppPageLoading from '../../../components/AppPageLoading';

import coverServerless from '../../../images/cover-serverless-grid.png';
import useStyles from './styles/grid';
import { Link } from 'react-router-dom';
// import DetailsDialog from './details';
import cover from '../../../images/cover-1.png';
function GridDownload() {
    const classes = useStyles();
    const { firestore, user } = useFirebase();

    const akses = firestore.collection(`akses`);

    const [snapshot, loading] = useCollection(akses.where('userId', '==', user.uid));

    const [aksesItems, setAksesItems] = useState([]);

    useEffect(() => {

        if (snapshot) {
            setAksesItems(snapshot.docs);
        }
    }, [snapshot])


    if (loading) {
        return <AppPageLoading />
    }
    return <>
        <Typography component="h1" variant="h5" paragraph>Daftar Akses</Typography>
        {
            aksesItems.length <= 0 &&
            <Typography>Belum ada akses download tersedia</Typography>
        }

        <Grid container spacing={5}>
            {
                aksesItems.map(aksesDoc => {
                    const aksesData = aksesDoc.data();
                    return <Grid key={aksesDoc.id} item xs={12} sm={6} md={4} lg={3}>
                        {/* <Card className={classes.card}>
                            <CardActionArea component={Link} to={`/download/details/${aksesDoc.id}`}>

                                <CardMedia
                                    className={classes.cover}
                                    image={cover}
                                    title={aksesData.produkId}
                                />

                                <CardContent className={classes.content}>
                                    <Typography variant="h5" component="h5" >{renderProduk(aksesData.produkId)}</Typography>
                                </CardContent>


                            </CardActionArea>

                        </Card> */}
                        <ButtonBase component={Link} to={`/download/details/${aksesDoc.id}`}>
                            <Paper className={classes.paper}>

                                <Image src={aksesData.produkId === 'react-aws-serverless' ? coverServerless : cover} alt="cover" />
                                <Typography variant="h6" component="h5" >{renderProduk(aksesData.produkId)}</Typography>

                            </Paper>
                        </ButtonBase>
                    </Grid>
                })
            }
        </Grid>

    </>
}

export default GridDownload;