import React, { useEffect } from 'react';



function PersistRef({ match }) {
    const { refid } = match.params;
    useEffect(() => {

        if (!localStorage.getItem('refid') && refid && refid !== 'undefined') {
            localStorage.setItem('refid', refid);
        }

    }, [refid])
    return <>
        <p>persist ref {match.params.refid}</p>
    </>
}

export default PersistRef;