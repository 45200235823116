import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { renderProduk } from '../../../utils/helper';
import { functions } from '../../../components/FirebaseProvider';
// import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

// const useStyles = makeStyles(theme => ({
//     table: {
//         minWidth: 300
//     },
//     buttonProgress: {

//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         marginTop: -12,
//         marginLeft: -12,
//     }, wrapper: {
//         margin: theme.spacing(1),
//         position: 'relative',
//     }

// }))

export default function DetailsServerless({ akses }) {
    const [loading, setLoading] = useState(false);
    const [link, setLink] = useState(null);
    const reqAksesToken = async () => {
        setLoading(true)
        try {
            const callReqAksesToken = functions.httpsCallable('reqAksesToken')


            const result = await callReqAksesToken({
                aksesId: akses.id
            })
            setLoading(false)
            const serverlessUrl = process.env.NODE_ENV === 'development' ? "http://localhost:3000/" : "https://serverless.jagoreact.com/";
            const aksesUrl = `${serverlessUrl}login-by-token/${result.data.customToken}`;
            //window.open(aksesUrl, '_blank');
            setLink(aksesUrl)
        }
        catch (e) {
            setLoading(false)
            console.log(e.message)
        }
    }
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography component="h1" variant="h5" paragraph>Akses {renderProduk(akses.produkId)}</Typography>
            <Button color="primary" variant="contained" href={akses.telegram} component="a">Gabung Dengan Telegram Group</Button>
        </Grid>
        <Grid item xs={12}>
            <Typography >Untuk dapat mengakses materi lengkap di serverless.jagoreact.com tekan tombol dibawah ini.</Typography>
        </Grid>
        <Grid item xs={12} container direction="column" alignItems="flex-start" spacing={2}>
            <Grid item xs={12}>
                <Button disabled={loading} variant="outlined" size="large" onClick={reqAksesToken}>Buat Link Akses serverless.jagoreact.com</Button>

            </Grid>
            <Grid item xs={12}>
                {loading && <CircularProgress size={24} />}
            </Grid>
            <Grid item xs={12}>
                {link && <Button variant="contained" color="primary" href={link} target="_blank">Klik Disini Untuk Mengakses</Button>}
            </Grid>
        </Grid>

        <Grid item xs={12}>
            <Typography color="error">
                Lisensi e-book hanya berlaku untuk 1 orang pengguna/pelajar.<br />
                    Dilarang keras untuk menyebar luaskan akses ini tanpa izin resmi tertulis dari JagoReact.com.<br />
                    Pelanggaran atas hak cipta e-book ini akan dikenakan hukuman sesuai dengan Pasal 113 Undang-Undang Nomor 28 tahun 2014 tentang Hak Cipta.
</Typography>
        </Grid>
    </Grid>
}