import React from 'react';

// import komponen materia UI 
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

// import styles
import useStyles from './styles';

function AppLoading() {
    const classes = useStyles();
    return (
        <main className={classes.main}>
            <div className={classes.loadingBox}>
                <Typography
                    className={classes.siteName}
                    variant="h6"
                    component="h2" >
                   JagoReact.com
                </Typography>
                <LinearProgress className={classes.loadingBar} />
            </div>
        </main>
    );
}

export default AppLoading;