import React, { useState, useEffect, useRef } from 'react';

// material-ui 
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
//icons
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import { useFirebase } from '../../../components/FirebaseProvider';

import { useCollection } from 'react-firebase-hooks/firestore';
import format from 'date-fns/format';
import isEmail from 'validator/lib/isEmail';
import AppPageLoading from '../../../components/AppPageLoading';

import useStyles from './styles/pengguna';
import { useSnackbar } from 'notistack';
import { currency } from '../../../utils/formatter';
//import DetailsDialog from './details';

function Pengguna() {
    const classes = useStyles();
    const { firestore, user, FieldValue } = useFirebase();
    const { enqueueSnackbar } = useSnackbar();
    const transaksiCol = firestore.collection(`order`)
    const emailRef = useRef();
    const [email, setEmail] = useState('');

    let query = transaksiCol.where('status', '==', 'success');
    if (email) {
        query = transaksiCol.where('email', '==', email).where('status', '==', 'success');
    }

    const [snapshot, loading] = useCollection(query);

    const [users, setUsers] = useState([]);
    const [omset, setOmset] = useState(0);

    useEffect(() => {

        if (snapshot) {
            setUsers(snapshot.docs.sort((a, b) => { return b.data().createdAt.toMillis() - a.data().createdAt.toMillis() }));
            const totalOmset = snapshot.docs.reduce((sum, val) => {

                return sum + parseInt(val.data().bayar)
            }, 0);

            setOmset(totalOmset);
        }
    }, [snapshot])



    const handleAddAccess = orderDoc => async e => {
        const orderData = orderDoc.data()
        if (window.confirm(`Anda yakin ingin menambahkan bonus akses download ke ${orderData.email}`)) {

            const aksesCol = firestore.collection(`akses`);

            await aksesCol.add({
                userId: orderData.userId,
                orderId: orderDoc.id,
                produkId: orderData.produkId === 'react-firebase' ? 'react-aws-serverless' : 'react-firebase',
                email: orderData.email,
                bonus: true,
                displayName: orderData.displayName,
                telegram: 'https://t.me/joinchat/CoxP4Ba4bNCjXAghMkePPQ'

            })

            // const orderDoc = firestore.doc(`order/${userData.orderId}`);
            await orderDoc.ref.set({ status_bonus: true }, { merge: true });
            // const orderSnap = await orderDoc.get();

            enqueueSnackbar('Bonus Akses berhasil aktifkan', { variant: 'success' });

        }
    }

    const handleSendReminder = orderSnap => async e => {
        const emailColl = firestore.collection('email');
        const orderData = orderSnap.data();
        try {
            await emailColl.add({
                orderId: orderSnap.id,
                email: orderData.email,
                bayar: orderData.bayar,
                displayName: orderData.displayName
            })

            enqueueSnackbar('Reminder telah dibuat dan sedang dikirim ke email' + orderData.email, { variant: 'success' });

        }
        catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    }

    const handleDelete = userDoc => async e => {

        if (window.confirm('Apakah anda yakin ingin menghapus transaksi ini?')) {
            await userDoc.ref.delete();
        }
    }

    const findEmail = (e) => {
        const emailvalue = emailRef.current.value;
        if (emailvalue && isEmail(emailvalue)) {

            setEmail(emailvalue);
        }
    }

    if (loading) {
        return <AppPageLoading />
    }
    return <>
        <Grid container spacing={5}>
            <Grid item xs={6} md={6}>
                <Typography>Total Penjualan ({snapshot.docs.length}): {currency(omset)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Cari email"
                    inputProps={{
                        ref: emailRef,
                        onBlur: findEmail
                    }}
                    margin="normal"
                    className={classes.searchField}
                />
            </Grid>

            {
                users.length <= 0 &&
                <Grid item xs={12}>
                    <Typography paragraph>Belum ada data order terbaru</Typography>
                </Grid>
            }

        </Grid>




        <Grid container spacing={5}>
            {
                users.map(userDoc => {
                    const userData = userDoc.data();

                    return <Grid key={userDoc.id} item xs={12} sm={12} md={6} >
                        <Card className={classes.card}>
                            <CardContent className={classes.transaksiSummary}>
                                <Typography noWrap>ID: {userDoc.id}</Typography>
                                <Typography>Email: {userData.email}</Typography>
                                <Typography>Nama: {userData.displayName}</Typography>
                                <Typography>Ref: {userData.refid}</Typography>
                                <Typography>
                                    Tagihan: {currency(userData.bayar)}
                                </Typography>
                                <Typography>
                                    Tanggal: {userData.createdAt && format(userData.createdAt.toDate(), 'DD-MM-YYYY HH:mm')}
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.transaksiActions}>
                                {!userData.status_bonus &&
                                    <IconButton
                                        onClick={handleAddAccess(userDoc)}
                                    ><AddIcon />
                                    </IconButton>}

                                <IconButton onClick={handleDelete(userDoc)}><DeleteIcon /></IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                })
            }
        </Grid>
        {/* <DetailsDialog
            open={details.open}
            handleClose={handleCloseDetails}
            transaksi={details.transaksi}
        /> */}
    </>
}

export default Pengguna;