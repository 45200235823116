import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    paper: {
        marginTop: 64,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 35
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: 24,
    },
    textField: {
        marginBottom: 24,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 24,
        marginBottom: 24,

    },

}))