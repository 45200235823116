import React from 'react';

import { Switch, Route } from 'react-router-dom';
import DetailsDownload from './details'
import GridDownload from './grid';



function Produk() {

    return (
        <Switch>
            <Route path="/order/details/:orderId" component={DetailsDownload} />
            <Route component={GridDownload} />
        </Switch>
    )

}

export default Produk;