import React, { useState, useEffect, useRef } from 'react';

// material-ui 
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';

import { useFirebase } from '../../../components/FirebaseProvider';

import { useCollection } from 'react-firebase-hooks/firestore';
import format from 'date-fns/format';
import isEmail from 'validator/lib/isEmail';
import AppPageLoading from '../../../components/AppPageLoading';

import useStyles from './styles/grid';
import { useSnackbar } from 'notistack';
import { currency } from '../../../utils/formatter';
import { Link } from 'react-router-dom';
//import DetailsDialog from './details';
import { renderProduk, renderStatus } from '../../../utils/helper';
function Pengguna() {
    const classes = useStyles();
    const { firestore, user } = useFirebase();
    const { enqueueSnackbar } = useSnackbar();
    const transaksiCol = firestore.collection(`order`)

    let query = transaksiCol.where('userId', '==', user.uid).limit(10);


    const [snapshot, loading, error] = useCollection(query);

    const [users, setUsers] = useState([]);


    useEffect(() => {

        if (snapshot) {
            setUsers(snapshot.docs);
        }
    }, [snapshot])



    const handleDelete = userDoc => async e => {

        if (window.confirm('Apakah anda yakin ingin menghapus transaksi ini?')) {
            await userDoc.ref.delete();
        }
    }


    if (loading) {
        return <AppPageLoading />
    }
    return <>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Typography component="h1" variant="h5">Daftar Order</Typography>
            </Grid>

            {
                users.length <= 0 &&
                <Grid item xs={12}>
                    <Typography paragraph>Belum ada data order</Typography>
                </Grid>
            }

        </Grid>




        <Grid container spacing={5}>
            {
                users.map(userDoc => {
                    const userData = userDoc.data();

                    return <Grid key={userDoc.id} item xs={12} sm={12} md={6} >
                        <Card className={classes.card}>
                            <CardActionArea component={Link} to={`/order/details/${userDoc.id}`}>

                                <CardContent className={classes.transaksiSummary}>
                                    <Typography noWrap>ID: {userDoc.id}</Typography>
                                    <Typography variant="subtitle2">Status: {renderStatus(userData.status)}</Typography>
                                    <Typography variant="subtitle2">Produk: </Typography>
                                    <Typography variant="caption">{renderProduk(userData.produkId)}</Typography>
                                    <Typography>
                                        Tagihan: {currency(userData.bayar)}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Tanggal: {userData.createdAt && format(userData.createdAt.toDate(), 'DD-MM-YYYY HH:mm')}
                                    </Typography>
                                </CardContent>
                                {/* <CardActions className={classes.transaksiActions}>
                                <IconButton
                                    onClick={handleAddAccess(userDoc)}
                                ><AddIcon /></IconButton>
                                <IconButton onClick={handleDelete(userDoc)}><DeleteIcon /></IconButton>
                            </CardActions> */}
                            </CardActionArea>
                        </Card>
                    </Grid>
                })
            }
        </Grid>
        {/* <DetailsDialog
            open={details.open}
            handleClose={handleCloseDetails}
            transaksi={details.transaksi}
        /> */}
    </>
}

export default Pengguna;