import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../../components/FirebaseProvider';
import { useDocument, useCollection } from 'react-firebase-hooks/firestore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import format from 'date-fns/format';
import AppPageLoading from '../../../components/AppPageLoading';
import { currency } from '../../../utils/formatter';
import useStyles from './styles/details';
import { Link } from 'react-router-dom';
import { renderProduk, renderStatus } from '../../../utils/helper';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import DetailsServerless from './details-serverless';

function DetailsDownload({ match }) {
    const classes = useStyles();
    const { firestore, FieldValue } = useFirebase();
    const { enqueueSnackbar } = useSnackbar();
    const aksesDocRef = firestore.doc(`akses/${match.params.aksesId}`);
    const [aksesSnap, loading] = useDocument(aksesDocRef);
    const downloadColRef = aksesDocRef.collection(`download`);
    const [downloadSnap, loadingDownload] = useCollection(downloadColRef.orderBy('createdAt', 'desc'));
    const [akses, setAkses] = useState({});
    const [downloads, setDownloads] = useState([]);

    useEffect(() => {

        if (aksesSnap) {
            setAkses({
                id: aksesSnap.id,
                ...aksesSnap.data()
            })
        }
    }, [aksesSnap])

    useEffect(() => {

        if (downloadSnap) {
            setDownloads(downloadSnap.docs);
        }

    }, [downloadSnap])

    const addDownloadLink = async (e) => {
        // if (downloads.length >= 3) {
        //     enqueueSnackbar('Maaf anda hanya bisa membuat link download sebanyak 3 kali!', { variant: 'error' })
        //     return false;
        // }
        try {
            await downloadColRef.add({
                status: 'Requested',
                createdAt: FieldValue.serverTimestamp()
            })
        } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' })
        }
    }

    if (loading || loadingDownload) {
        return <AppPageLoading />
    }


    if (akses.produkId === 'react-aws-serverless') {

        return <DetailsServerless akses={akses} />
    }


    return <>


        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Typography component="h1" variant="h5" paragraph>Download {renderProduk(akses.produkId)}</Typography>
                <Button color="primary" variant="contained" href={akses.telegram} component="a">Gabung Dengan Telegram Group</Button>
            </Grid>

            <Grid item xs={12}>
                <Button variant="outlined" size="large" onClick={addDownloadLink}>Buat Download Link</Button>
            </Grid>
            <Grid item xs={12}>
                <Typography >Tips: Gunakan downloader bawaan browser untuk menghindari file corrupt.</Typography>
            </Grid>
            <Grid item xs={12}>

                <Table className={classes.table} >
                    <TableHead>
                        <TableRow>

                            <TableCell >Download Link</TableCell>
                            <TableCell >Expired</TableCell>
                            <TableCell >Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {downloads.length <= 0 &&
                            <TableRow >
                                <TableCell colSpan={2} >Belum ada download link </TableCell>
                            </TableRow>
                        }
                        {downloads.map((download) => {
                            const downloadData = download.data();
                            const expireMillis = downloadData.expireInSeconds ? downloadData.createdAt.toMillis() + (downloadData.expireInSeconds * 1000) : 0;
                            const isExpired = Date.now() > expireMillis && downloadData.status !== 'Requested';
                            return <TableRow key={download.id} >

                                <TableCell >
                                    {isExpired ? 'Link Expired' : <>
                                        {/* Single File:<br />
                                        {downloadData.url ? <Button color="primary" href={downloadData.url} component="a">Download (6.5GB)</Button> : 'Creating...'}
                                        <br /> */}
                                        File dibagi menjadi 7 part:
                                <br />
                                        {downloadData.urlPart1 ? <Button color="primary" href={downloadData.urlPart1} component="a">Download Part 1 (936 MB)</Button> : 'Creating Part 1...'}
                                        <br />
                                        {downloadData.urlPart2 ? <Button color="primary" href={downloadData.urlPart2} component="a">Download Part 2 (1 GB)</Button> : 'Creating Part 2...'}
                                        <br />
                                        {downloadData.urlPart3 ? <Button color="primary" href={downloadData.urlPart3} component="a">Download Part 3 (872 MB)</Button> : 'Creating Part 3...'}
                                        <br />
                                        {downloadData.urlPart4 ? <Button color="primary" href={downloadData.urlPart4} component="a">Download Part 4 (772 MB)</Button> : 'Creating Part 4...'}
                                        <br />
                                        {downloadData.urlPart5 ? <Button color="primary" href={downloadData.urlPart5} component="a">Download Part 5 (890 MB)</Button> : 'Creating Part 5...'}
                                        <br />
                                        {downloadData.urlPart6 ? <Button color="primary" href={downloadData.urlPart6} component="a">Download Part 6 (973 MB)</Button> : 'Creating Part 6...'}
                                        <br />
                                        {downloadData.urlPart7 ? <Button color="primary" href={downloadData.urlPart7} component="a">Download Part 7 (1.3 GB)</Button> : 'Creating Part 7...'}
                                    </>}
                                </TableCell>
                                <TableCell >{expireMillis && format(new Date(expireMillis), 'DD-MM-YY HH:mm')}</TableCell>
                                <TableCell >{isExpired ? 'Expired' : downloadData.status}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12}>
                <Typography color="error">
                    Lisensi video hanya berlaku untuk 1 orang pengguna/pelajar.<br />
                    Dilarang keras untuk menyebar luaskan video ini tanpa izin resmi tertulis dari JagoReact.com.<br />
                    Pelanggaran atas hak cipta video ini akan dikenakan hukuman sesuai dengan Pasal 113 Undang-Undang Nomor 28 tahun 2014 tentang Hak Cipta.
</Typography>
            </Grid>
            {/* <Grid item xs={12}>
                <Table className={classes.table} >
                    <TableHead>
                        <TableRow>

                            <TableCell align="right">Jumlah.</TableCell>
                            <TableCell align="right">Harga</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow >

                            <TableCell >Harga</TableCell>
                            <TableCell align="right"><strike>{currency(order.hargaNormal)}</strike></TableCell>
                        </TableRow>


                        <TableRow>
                            <TableCell rowSpan={1} />
                            <TableCell >Subtotal</TableCell>
                            <TableCell align="right"><strike>{currency(order.hargaNormal)}</strike></TableCell>
                        </TableRow> *
                <TableRow>

                            <TableCell >Discont {order.diskon * 100}%/{order.kupon}</TableCell>

                            <TableCell align="right">-{currency(order.diskon * order.hargaNormal)}</TableCell>
                        </TableRow>
                        <TableRow>

                            <TableCell >Kode Unik</TableCell>

                            <TableCell align="right">-{currency(order.kodeUnik)}</TableCell>
                        </TableRow>
                        <TableRow>

                            <TableCell>Total yang harus dibayar</TableCell>
                            <TableCell align="right">{currency(order.bayar)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid> */}


        </Grid >
    </>
}


export default DetailsDownload;