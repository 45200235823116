import React from 'react';


import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// komponen halaman
import Login from './pages/login';
import LupaPassword from './pages/lupa-password';
import PersistRef from './pages/ref';
import SignUp from './pages/signup';

import SignUpServerless from './pages/signup/serverless';

import SignUpServerlessPromo from './pages/signup/serverless-promo';
import NotFound from './pages/404';
import Private from './pages/private';
import PrivateRoute from './components/PrivateRoute';

// firebase context provider
import FirebaseProvider from './components/FirebaseProvider';
import { SnackbarProvider } from 'notistack';
// import komponen material-ui
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import theme from './config/theme';

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
          <FirebaseProvider>
            <Router>
              <Switch>
                <PrivateRoute path="/" exact component={Private} />
                <PrivateRoute path="/download" component={Private} />
                <PrivateRoute path="/affiliate" component={Private} />
                <PrivateRoute path="/admin" component={Private} />
                <PrivateRoute path="/pengaturan" component={Private} />
                <PrivateRoute path="/order" component={Private} />

                <Route path="/login" component={Login} />
                <Route path="/lupa-password" component={LupaPassword} />
                <Route path="/signup" component={SignUp} />
                <Route path="/signup-serverless" component={SignUpServerless} />
                <Route path="/signup-serverless-promo" component={SignUpServerlessPromo} />
                <Route path="/persist-ref/:refid" component={PersistRef} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </FirebaseProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
