import React, { useState } from 'react';


// import Material UI components
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import styles
import useStyles from './styles';

// import komponen React Router DOM untuk navigasi ke halaman Login
import { Link, Redirect } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';

// import valdiator 
import isEmail from 'validator/lib/isEmail';

// import firebase hook
import { useFirebase } from '../../components/FirebaseProvider';

// import komponen umum / app
import AppLoading from '../../components/AppLoading';

// buat sebuah fungsi komponen dengan nama Login
function Login(props) {
    const { location } = props;
    const classes = useStyles();
    const [form, setForm] = useState({
        email: '',
        password: ''
    });

    const [error, setError] = useState({
        email: '',
        password: ''
    });


    const [isSubmitting, setSubmitting] = useState(false);


    // useFirebase hook untuk memonitor perubahan pada user state
    const { loading, user, auth } = useFirebase();

    // fungsi untuk menangani event onChange pada TextField material-ui
    const handleChange = e => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

        setError({
            ...error,
            [e.target.name]: ''
        });
    };

    // fungsi untuk memvalidasi inputan pengguna email,password, dan repeat_password
    const validate = () => {

        const newError = { ...error };

        if (!form.email) {
            newError.email = 'Email wajib diisi';
        } else if (!isEmail(form.email)) {
            newError.email = 'Email tidak valid';
        }

        if (!form.password) {
            newError.password = 'Password wajib diisi';
        }
        return newError;
    };

    // fungsi (async) untuk menangani registrasi
    const handleSubmit = async e => {
        e.preventDefault();

        const findErrors = validate();

        if (Object.keys(findErrors).length > 0
            && Object.values(findErrors).some(err => err !== '')) {
            setError(findErrors);
        } else {
            try {
                setSubmitting(true);
                await auth
                    .signInWithEmailAndPassword(form.email, form.password);

            } catch (e) {
                const newError = {};
                switch (e.code) {
                    case 'auth/user-not-found':
                        newError.email = 'Email tidak terdaftar';
                        break;
                    case 'auth/invalid-email':
                        newError.email = 'Email tidak valid';
                        break;
                    case 'auth/wrong-password':
                        newError.password = 'Password salah';
                        break;
                    case 'auth/user-disabled':
                        newError.email = 'Pengguna di Blokir';
                        break;
                    default:
                        newError.email = 'Terjadi kesalahan silahkan coba lagi';
                        break;
                }
                setError(newError);
                setSubmitting(false);
            }
        }
    };

    if (loading) {
        return <AppLoading />
    }

    if (user) {
        const redirectTo = location.state
            && location.state.from
            && location.state.from.pathname ?
            location.state.from.pathname : '/';
        return <Redirect to={redirectTo} />
    }


    return (
        <Container maxWidth="xs" component="main">
            <Paper className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Typography component="h2" variant="h6">
                    JagoReact.com
                </Typography>
                <form onSubmit={handleSubmit} className={classes.form} noValidate>
                    <TextField
                        id="email"
                        name="email"
                        type="email"
                        fullWidth
                        value={form.email}
                        label="Alamat Email"
                        placeholder="you@email.com"
                        className={classes.textField}
                        onChange={handleChange}
                        helperText={error.email}
                        error={error.email ? true : false}
                        disabled={isSubmitting}
                        variant="outlined"
                        required

                    />
                    <TextField
                        id="password"
                        name="password"
                        type="password"
                        fullWidth
                        value={form.password}
                        label="Password"
                        placeholder="******"
                        className={classes.textField}
                        onChange={handleChange}
                        helperText={error.password}
                        error={error.password ? true : false}
                        variant="outlined"
                        disabled={isSubmitting}
                        required
                    />

                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Login
                        </Button>
                        <Button component={Link} to="/lupa-password">
                            Lupa Password?
                    </Button>
                    </div>

                    <Grid container sapacing={2}>
                        <Grid item xs={12}>
                            <MaterialLink

                                component={Link}
                                to={'/signup'}
                            // color="initial"
                            >
                                Order Video React Firebase
                        </MaterialLink>
                        </Grid>
                        <Grid item xs={12}>
                            <MaterialLink

                                component={Link}
                                to={'/signup-serverless'}
                            // color="initial"
                            >
                                (New) Order E-Book React AWS Serverless
                        </MaterialLink>
                        </Grid>

                    </Grid>


                </form>
            </Paper>
        </Container>);
}


export default Login;