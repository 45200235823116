import React from 'react';

// import komponen material ui 
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// import komponen React Router DOM
import { Link } from 'react-router-dom';

// import styles
import useStyles from './styles';

// buat sebuah fungsi komponen dengan nama NotFound
function NotFound(props) {
    const classes = useStyles();
    return (
        <Container maxWidth="xs" component="main">
            <Paper className={classes.paper}>
                <Typography variant="subtitle1">Halaman Tidak Ditemukan</Typography>
                <Typography variant="h3" >404</Typography>
                <Typography component={Link} to="/">Kembali ke Beranda</Typography>
            </Paper>
        </Container>
    );
}

export default NotFound;