import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    paper: {
        //    // marginTop: 64,
        //     display: 'flex',
        //     flexDirection: 'column',
        //     alignItems: 'center',
        // paddingTop: theme.spacing(6),
        // //  paddingBottom: theme.spacing(4),
        // [theme.breakpoints.down('sm')]: {
        //     paddingTop: theme.spacing(4),
        //     // paddingBottom: theme.spacing(2),
        // },
        // minHeight: '100vh',
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: 24,
    },
    textField: {
        marginBottom: 24,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 24,
        marginBottom: 24
    },
    cover: {
        textAlign: 'center',
        width: "100%",
        height: "100%"
    },
    coverImage: {
        width: "100%"
    },
    iconLeft: {
        marginRight: theme.spacing(1)
    },
    buttonProgress: {

        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    footer: {
        padding: theme.spacing(2),
        marginTop: 'auto',
        backgroundColor: 'white',
    },
}))