import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({

    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh'
    }
}));

export default useStyles;