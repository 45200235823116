import React from 'react';

import { Switch, Route } from 'react-router-dom';
import DetailsDownload from './details'
import GridDownload from './grid';



function Produk() {

    return (
        <Switch>
            <Route path="/download/details/:aksesId" component={DetailsDownload} />
            <Route component={GridDownload} />
        </Switch>
    )

}

export default Produk;