import React, { useState, useRef, useCallback, useEffect } from 'react';


// import Material UI components
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// import styles
import useStyles from './styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import komponen React Router DOM untuk navigasi ke halaman Login
//import { Link, Redirect } from 'react-router-dom';

// import valdiator 
import isEmail from 'validator/lib/isEmail';
import Countdown from 'react-countdown-now';
// import firebase hook
import { useFirebase } from '../../components/FirebaseProvider';

// import komponen umum / app
import AppLoading from '../../components/AppLoading';
import { useSnackbar } from 'notistack';
import cover from '../../images/cover-2.png';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import Hidden from '@material-ui/core/Hidden';

const trackOrder = () => {
    window.fbq('trackCustom', 'OrderForm');

    // if (typeof window.gtag_report_conversion === 'function') {
    //     window.gtag_report_conversion();
    // }
}
// buat sebuah fungsi komponen dengan nama Login
function SignUp({ history }) {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const kuponRef = useRef();
    const focusInputRef = useRef();
    const [redirectSucces, setRedirectSuccess] = useState(null);

    // useFirebase hook untuk memonitor perubahan pada user state
    const { loading, firestore, user, auth, FieldValue } = useFirebase();

    const [form, setForm] = useState({
        nama: user?.displayName ?? '',
        email: user?.email ?? '',
        password: '',
        whatsapp: ''

    });

    const [error, setError] = useState({
        nama: '',
        email: '',
        password: '',
        whatsapp: '',
        kupon: ''
    });


    const [isSubmitting, setSubmitting] = useState(false);


    useEffect(() => {
        if (redirectSucces) {
            console.log("track order")
            trackOrder();
            history.push(`/order/details/${redirectSucces}`);
        }

    }, [redirectSucces, history]);

    // fungsi untuk menangani event onChange pada TextField material-ui
    const handleChange = e => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

        setError({
            ...error,
            [e.target.name]: ''
        });
    };

    // fungsi untuk memvalidasi inputan pengguna email,password, dan repeat_password
    const validate = () => {

        const newError = {};
        if (user) {
            return {};
        }
        if (!form.nama) {
            newError.nama = 'Nama wajib diisi';
        }
        if (!form.email) {
            newError.email = 'Email wajib diisi';
        } else if (!isEmail(form.email)) {
            newError.email = 'Email tidak valid';
        }

        if (!form.password) {
            newError.password = 'Password wajib diisi';
        }
        return newError;
    };

    // const validateKupon = async e => {
    //     console.log(kuponRef.current.value);

    // }
    // fungsi (async) untuk menangani registrasi
    const handleSubmit = async e => {
        e.preventDefault();

        const findErrors = validate();

        if (Object.keys(findErrors).length > 0
            && Object.values(findErrors).some(err => err !== '')) {
            setError(findErrors);
        } else {
            try {
                setSubmitting(true);
                const orderCollRef = firestore.collection(`order`);
                const refid = localStorage.getItem('refid');
                let orderDoc;
                if (!user) {
                    const newUser = await auth
                        .createUserWithEmailAndPassword(form.email, form.password);
                    await newUser.user.updateProfile({ displayName: form.nama });

                    const userDocRef = firestore.doc(`users/${newUser.user.uid}`);


                    orderDoc = await orderCollRef.add({
                        produkId: 'react-firebase',
                        kupon: kuponRef.current.value,
                        userId: newUser.user.uid,
                        displayName: form.nama,
                        email: form.email,
                        status: 'created',
                        refid: refid ? refid : false,
                        createdAt: FieldValue.serverTimestamp()
                    });

                    await userDocRef.set({
                        whatsapp: form.whatsapp,
                        affiliate: true,
                        displayName: form.nama,
                        email: form.email,
                        orderId: orderDoc.id,
                        refid: newUser.user.uid,
                        komisi: 0.2,
                        createdAt: FieldValue.serverTimestamp()
                    }, { merge: true })
                } else {
                    orderDoc = await orderCollRef.add({
                        produkId: 'react-firebase',
                        kupon: kuponRef.current.value,
                        userId: user.uid,
                        displayName: form.nama,
                        email: form.email,
                        status: 'created',
                        refid: refid ? refid : false,
                        createdAt: FieldValue.serverTimestamp()
                    });
                }

                setSubmitting(false);
                enqueueSnackbar('Terimakasih Order berhasil dibuat', { variant: 'success' })

                setRedirectSuccess(orderDoc.id)
            } catch (e) {
                console.log(e.message)
                const newError = {};
                switch (e.code) {
                    case 'auth/email-already-in-use':
                        newError.email = 'Email sudah terdaftar, silahkan login terlebih dahulu di https://member.jagoreact.com/login';
                        break;
                    case 'auth/invalid-email':
                        newError.email = 'Email tidak valid';
                        break;
                    case 'auth/weak-password':
                        newError.password = 'Password lemah';
                        break;
                    case 'auth/operation-not-allowed':
                        newError.email = "Metode email dan password tidak didukung"
                        break;
                    default:
                        newError.email = 'Terjadi kesalahan silahkan coba lagi';
                        break;
                }
                setError(newError);
                setSubmitting(false);
            }
        }
    };

    const focusInput = (e) => {

        focusInputRef.current.focus()
    }

    if (loading) {
        return <AppLoading />
    }


    console.log(user)
    // if (user) {
    //     const redirectTo = location.state
    //         && location.state.from
    //         && location.state.from.pathname ?
    //         location.state.from.pathname : '/';
    //     return <Redirect to={redirectTo} />
    // }


    return (<div className={classes.root}>
        <Container maxWidth="md" component="main">

            <div className={classes.paper}>
                <Typography component="h1" variant="h4" align="center" paragraph>
                    Form Order Video Tutorial
                </Typography>
                <Typography component="h2" variant="h5" align="center" paragraph>
                    Jago Membuat Aplikasi Web Modern dengan React dan Firebase
                </Typography>

                <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12}>
                        <Countdown
                            date={'Mon, 1 Jun 2020 00:00:00'}
                            renderer={({ completed, days, hours, minutes, seconds }) => {
                                if (completed) {
                                    return <>
                                        <Typography variant="h4" align="center" color="error">Diskon 65%</Typography>
                                        <Typography variant="h6" align="center" color="secondary"><strike>Rp450.000</strike> -> Rp157.500</Typography>

                                    </>;
                                }
                                return <>
                                    <Typography variant="h4" align="center" color="error">Diskon 70%</Typography>
                                    <Typography variant="h6" align="center" color="secondary"><strike>Rp450.000</strike> -> Rp135.000</Typography>
                                    <Typography align="center">Penwaran Berakhir dalam:</Typography>
                                    <Typography variant="h5" align="center" paragraph>{days}<small>h</small> {hours}<small>j</small> {minutes}<small>m</small> {seconds}<small>d</small></Typography>
                                </>
                            }}
                        />
                        {user === null && <Hidden smUp>
                            <div className={classes.buttonContainer}>
                                <Button
                                    disabled={isSubmitting}
                                    variant="outlined" size="large" color="primary"
                                    onClick={focusInput}
                                ><ShoppingCartIcon className={classes.iconLeft} />Order Sekarang</Button>
                            </div>

                        </Hidden>}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div className={classes.cover}>
                            <img className={classes.coverImage} src={cover} alt="Cover Jago React" />

                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Akses Download 47 Video dan Source Code"
                                    />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Akses Ke Private Telegram Group"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Lisensi Untuk 1 Orang"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Lifetime Update"
                                    />
                                </ListItem>
                            </List>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} id="order-now">
                        <form onSubmit={handleSubmit} className={classes.form} noValidate>
                            <TextField
                                id="nama"
                                name="nama"
                                fullWidth
                                value={form.nama}
                                label="Nama Lengkap"
                                placeholder="Yayan Ruhian"
                                className={classes.textField}
                                onChange={handleChange}
                                helperText={error.nama}
                                error={error.nama ? true : false}
                                disabled={isSubmitting || user !== null}
                                variant="outlined"
                                required
                                inputProps={{
                                    ref: focusInputRef
                                }}

                            />
                            <TextField
                                id="email"
                                name="email"
                                type="email"
                                fullWidth
                                value={form.email}
                                label="Alamat Email Valid"
                                placeholder="you@email.com"
                                className={classes.textField}
                                onChange={handleChange}
                                helperText={error.email}
                                error={error.email ? true : false}
                                disabled={isSubmitting || user !== null}
                                variant="outlined"
                                autoComplete="nope"
                                required

                            />
                            {user === null && <>
                                <TextField
                                    id="password"
                                    name="password"
                                    type="password"
                                    fullWidth
                                    value={form.password}
                                    label="Password Untuk Member Area"
                                    placeholder="******"
                                    className={classes.textField}
                                    onChange={handleChange}
                                    helperText={error.password}
                                    error={error.password ? true : false}
                                    variant="outlined"
                                    autoComplete="new-password"
                                    disabled={isSubmitting || user !== null}
                                    required
                                />
                                <TextField
                                    id="whatsapp"
                                    name="whatsapp"
                                    fullWidth
                                    value={form.whatsapp}
                                    label="No Whatsapp"
                                    placeholder="62896530xxxxx"
                                    className={classes.textField}
                                    onChange={handleChange}
                                    helperText={error.whatsapp}
                                    error={error.whatsapp ? true : false}
                                    disabled={isSubmitting}
                                    variant="outlined"


                                />
                            </>}
                            <TextField
                                id="kupon"
                                name="kupon"
                                fullWidth
                                defaultValue="PROMO-2020"
                                label="Kupon Promo"
                                placeholder="jika ada"
                                className={classes.textField}
                                inputProps={{
                                    ref: kuponRef,
                                    // onBlur: validateKupon,
                                    readOnly: true
                                }}

                                helperText={error.kupon}
                                error={error.kupon ? true : false}
                                disabled={isSubmitting}
                                variant="outlined"


                            />

                            <div className={classes.buttonContainer}>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                >
                                    <ShoppingCartIcon className={classes.iconLeft} />   Order Sekarang
                                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>

                            </div>


                        </form>
                    </Grid>
                </Grid>

            </div>
        </Container>
        <footer className={classes.footer}>
            <Container maxWidth="sm">
                <Typography variant="body2" color="textSecondary">
                    {'Built with love by the '}
                    <Link color="inherit" href="https://jagoreact.com/">
                        JagoReact.com
                    </Link>
                    {' team.'}
                </Typography>
            </Container>
        </footer>
    </div>);
}


export default SignUp;