import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { useFirebase } from './FirebaseProvider';

import { useDocument } from 'react-firebase-hooks/firestore';

import AppPageLoading from './AppPageLoading';

function AdminRoute({ component: Component, ...restProps }) {

    const { user, firestore } = useFirebase();
    const roleDoc = firestore.doc(`roles/${user.uid}`)
    const [role, roleLoading, error] = useDocument(roleDoc);

    return <Route
        {...restProps}

        render={props => {
            if (roleLoading) {
                return <AppPageLoading />
            }
            return role && role.data() && role.data().admin ?
                <Component {...props} />
                :
                <Redirect to="/" />
        }}

    />
}

export default AdminRoute;