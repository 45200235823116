
export const renderStatus = (status) => {

    switch (status) {

        case 'created':
            return 'Dibuat';
        case 'waiting-for-payment':
            return 'Menunggu Pembayaran';
        case 'success':
            return 'Selesai';
        default:
            return 'Draft';
    }
}

export const renderProduk = (produkId) => {
    switch (produkId) {
        case 'react-aws-serverless':
            return 'E-Book Membangun Aplikasi Full-Stack Serverless dengan AWS dan React';
        default:
            return 'Jago Membuat Aplikasi Web Modern dengan React dan Firebase';

    }
}