import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { useFirebase } from './FirebaseProvider';

import { useDocument } from 'react-firebase-hooks/firestore';

import AppPageLoading from './AppPageLoading';

import AffiliateInfo from '../pages/private/affiliate/info';

function AffiliateRoute({ component: Component, ...restProps }) {

    const { user, firestore } = useFirebase();
    const roleDoc = firestore.doc(`users/${user.uid}`)
    const [role, roleLoading, error] = useDocument(roleDoc);

    return <Route
        {...restProps}

        render={props => {
            if (roleLoading) {
                return <AppPageLoading />
            }
            return role && role.data().affiliate ?
                <Component {...props} affiliate={role} />
                :
                <AffiliateInfo {...props} />
        }}

    />
}

export default AffiliateRoute;