import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 300
    }

}))


export default useStyles;