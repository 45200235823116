import React from 'react';


import useStyles from './styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
function Home() {
    const classes = useStyles();

    return <>

        <Container maxWidth="sm" className={classes.container}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5" paragraph>Segera Tersedia untuk Semua Member</Typography>
                    <Typography variant="subtitle2" paragraph>Dapatkan komisi dengan mengajak orang untuk membeli produk JagoReact.com</Typography>
                    <Typography>informasi: info@jagoreact.com</Typography>
                </Grid>
            </Grid>
        </Container>
    </>
}

export default Home;