
export const firebaseConfig = {
    apiKey: "AIzaSyBncyQUfN81qkOwyuIut12o_SSF2QFc4Y0",
    authDomain: "prod-jagoreact-com.firebaseapp.com",
    databaseURL: "https://prod-jagoreact-com.firebaseio.com",
    projectId: "prod-jagoreact-com",
    storageBucket: "",
    messagingSenderId: "973515628544",
    appId: "1:973515628544:web:eaaaa58f4ddb0ef5"
  };

export default firebaseConfig;