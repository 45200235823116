import React from 'react';


import useStyles from './styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
function Home() {
    const classes = useStyles();

    return <>
        <Container maxWidth="sm" className={classes.container}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography align="center" variant="h5">Selamat Datang di Member Area JagoReact.com</Typography>
                </Grid>
            </Grid>
        </Container>
    </>
}

export default Home;