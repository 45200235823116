import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../../components/FirebaseProvider';
import { useDocument } from 'react-firebase-hooks/firestore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import format from 'date-fns/format';
import AppPageLoading from '../../../components/AppPageLoading';
import { currency } from '../../../utils/formatter';
import useStyles from './styles/details';
import { Link } from 'react-router-dom';
import { renderProduk, renderStatus } from '../../../utils/helper';

function DetailsDownload({ match }) {
    const classes = useStyles();
    const { firestore } = useFirebase();
    const [orderSnap, loading] = useDocument(firestore.doc(`order/${match.params.orderId}`))
    const [order, setOrder] = useState({});

    useEffect(() => {

        if (orderSnap) {
            setOrder({
                id: orderSnap.id,
                ...orderSnap.data()
            })
        }
    }, [orderSnap])
    if (loading || !order.bayar) {
        return <AppPageLoading />
    }
    return <>


        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Typography component="h1" variant="h5" paragraph>Order ID: {order.id}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography >Nama Pemesan: {order.displayName}</Typography>
                <Typography>Email Pemesan: {order.email}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography >Lisensi: 1 Pengguna</Typography>
                <Typography >Tanggal Pemesanan: {order.createdAt && format(order.createdAt.toDate(), 'DD-MM-YYYY HH:mm')}</Typography>
                <Typography >Status: {renderStatus(order.status)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>Produk</Typography>
                <Typography>{renderProduk(order.produkId)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Table className={classes.table} >
                    {/* <TableHead>
                        <TableRow>
                          
                            <TableCell align="right">Jumlah.</TableCell>
                            <TableCell align="right">Harga</TableCell>
                        </TableRow>
                    </TableHead> */}
                    <TableBody>

                        <TableRow >

                            <TableCell >Harga</TableCell>
                            <TableCell align="right"><strike>{currency(order.hargaNormal)}</strike></TableCell>
                        </TableRow>


                        {/* <TableRow>
                            <TableCell rowSpan={1} />
                            <TableCell >Subtotal</TableCell>
                            <TableCell align="right"><strike>{currency(order.hargaNormal)}</strike></TableCell>
                        </TableRow> */}
                        <TableRow>

                            <TableCell >Discont {order.diskon * 100}%/{order.kupon}</TableCell>

                            <TableCell align="right">-{currency(order.diskon * order.hargaNormal)}</TableCell>
                        </TableRow>
                        <TableRow>

                            <TableCell >Kode Unik</TableCell>

                            <TableCell align="right">-{currency(order.kodeUnik)}</TableCell>
                        </TableRow>
                        <TableRow>

                            <TableCell>Total yang harus dibayar</TableCell>
                            <TableCell align="right">{currency(order.bayar)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} md={6} >

            </Grid>
            <Grid item xs={12} md={6} >
                <Typography paragraph>Metode Pembayaran:</Typography>
                <Typography>Silahkan lakukan transfer sejumlah:</Typography>
                <Typography paragraph variant="h6"> {currency(order.bayar)} </Typography>
                <Typography>ke rekening:</Typography>
                <Typography paragraph variant="h6">BCA 0540927464 a.n Yuda Sukmana</Typography>
                <Typography paragraph variant="caption">* jumlah transfer harus sama persis dengan yang tertera pada halaman ini untuk memudahkan proses verifikasi</Typography>
                <Typography paragraph>Setelah anda transfer, silahkan lakukan konfirmasi pembayaran dengan mengirimkan data berupa:</Typography>
                <Typography component="ul" paragraph>
                    <li>alamat email (yang didaftarkan saat order)</li>
                    <li>informasi rekening pengirim (misalnya: BNI 123456789 a.n Nike Sartika)</li>
                    <li>jumlah transfer:{currency(order.bayar)}</li>
                    <li>waktu transfer (tanggal dan jam)</li>
                    <li>foto/screenshot bukti transfer</li>
                </Typography>
                <Typography >Kirimkan data tersebut ke whatsapp:</Typography>
                <Typography paragraph variant="h6" component="a" href="https://wa.me/6289653023212">089653023212</Typography>
                <Typography >Atau ke email:</Typography>
                <Typography paragraph variant="h6" >info@jagoreact.com</Typography>
                <Typography paragraph>Segera setelah pembayaran anda diterima, kami akan mengaktifkan akses download di halaman <Link to="/download">Download Produk</Link></Typography>
            </Grid>

        </Grid>
    </>
}


export default DetailsDownload;