import React, { useState } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import LinkIcon from '@material-ui/icons/Link';
import SettingsIcon from '@material-ui/icons/Settings';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import OrderIcon from '@material-ui/icons/ShoppingCart';
import useStyles from './styles';

import { Switch, Route } from 'react-router-dom';

import Home from './home';
import Pengaturan from './pengaturan';
import Admin from './admin';
import Download from './download';
import Affiliate from './affiliate';
import Order from './order';
import AdminRoute from '../../components/AdminRoute';
import AffiliateRoute from '../../components/AffiliateRoute';

import { useFirebase } from '../../components/FirebaseProvider';
function Private(props) {
    const { history } = props;
    const classes = useStyles();
    const { auth } = useFirebase();
    const [open, setOpen] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogout = (e) => {
        if (window.confirm('Are you sure want to sign out?'))
            auth.signOut();
    }
    return (
        <div className={classes.root}>

            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        <Switch>

                            <Route path="/pengaturan" children="Pengaturan" />
                            <Route path="/download" children="Download" />
                            <Route path="/affiliate" children="Affiliate" />
                            <Route path="/order" children="Order" />

                            <Route children="Home" />

                        </Switch>
                    </Typography>
                    <IconButton onClick={handleLogout} color="inherit">
                        <SignOutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <Typography variant="h6" component="h1">JagoReact.com</Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <Route path="/" exact={true} children={({ match }) => {

                        return <ListItem button selected={match ? true : false} onClick={() => history.push('/')}>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItem>

                    }} />
                    <Route path="/download" children={({ match }) => {

                        return <ListItem button selected={match ? true : false} onClick={() => history.push('/download')}>
                            <ListItemIcon>
                                <DownloadIcon />
                            </ListItemIcon>
                            <ListItemText primary="Download" />
                        </ListItem>

                    }} />
                    <Route path="/order" children={({ match }) => {

                        return <ListItem button selected={match ? true : false} onClick={() => history.push('/order')}>
                            <ListItemIcon>
                                <OrderIcon />
                            </ListItemIcon>
                            <ListItemText primary="Order" />
                        </ListItem>

                    }} />
                    <Route path="/affiliate" children={({ match }) => {

                        return <ListItem button selected={match ? true : false} onClick={() => history.push('/affiliate')}>
                            <ListItemIcon>
                                <LinkIcon />
                            </ListItemIcon>
                            <ListItemText primary="Affiliate" />
                        </ListItem>

                    }} />
                    <Route path="/pengaturan" children={({ match, history }) => {

                        return <ListItem
                            button
                            selected={match ? true : false}
                            onClick={() => {
                                history.push('/pengaturan')
                            }}
                        >
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Pengaturan" />
                        </ListItem>

                    }} />

                </List>

            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Switch>
                        <AdminRoute component={Admin} path="/admin" />
                        <AffiliateRoute component={Affiliate} path="/affiliate" />
                        <Route component={Pengaturan} path="/pengaturan" />
                        <Route component={Download} path="/download" />
                        <Route component={Order} path="/order" />

                        <Route component={Home} />
                    </Switch>
                </Container>
            </main>
        </div>
    );
}


export default Private;